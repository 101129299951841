import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";

// seo
import Seo from "../components/Seo";

// services
import { sendToLSQ } from "../services/LeadSquared";
import { Mixpanel, MixpanelEvents } from "../services/Mixpanel";

// utils
import { saveUTM } from "../utils";

// components
import Header from "../components/LandingPages/Template_1/Header";
import Banner from "../components/LandingPages/Template_1/Banner";
import TwinContent from "../components/LandingPages/Template_1/TwinContent";
import PersonalizedSolution from "../components/LandingPages/Template_1/PersonalisedSolution";
import TwinReverse from "../components/LandingPages/Template_1/TwinReverse";
import Testimonial from "../components/LandingPages/Template_1/Testimonial";
import GetInTouch from "../components/LandingPages/Template_1/GetInTouch";
import WhatIsTwin from "../components/LandingPages/Template_1/WhatIsTwin";
import SuccessfulSection from "../components/LandingPages/Template_1/SuccessfulSection";
import FaqSection from "../components/LandingPages/Template_1/FaqSection";
import HealthAndHappinessSec from "../components/LandingPages/Template_1/Health&HappinessSec";
import ConnectUsForm from "../components/LandingPages/Template_1/ConnectUsForm";

const TwinConsultationPage = props => {
  const [depth25, setDepth25] = useState(true);
  const [depth50, setDepth50] = useState(true);
  const [depth75, setDepth75] = useState(true);
  const [depth100, setDepth100] = useState(true);
  const [loading, setLoading] = useState(false);
  const [operatingSystem, setOperatingSystem] = useState(""); // To store operating system
  const [deviceType, setDeviceType] = useState(""); // To store the device type

  // Save utm to local storage
  useEffect(() => {
    if (props.location.search) {
      saveUTM(props.location.search);
    }
  }, [props.location.search]);

  useEffect(() => {
    let loadTime =
      window.performance.timing.domContentLoadedEventEnd -
        window.performance.timing.navigationStart || 700;
    // console.log("loadTime", loadTime);

    Mixpanel.track(MixpanelEvents.page_viewed, {
      page_name: "twin-consultation",
      load_time: loadTime,
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const pageYOffset = window.pageYOffset;
      const height = document.body.scrollHeight - window.innerHeight;
      const progress = Math.round((pageYOffset / height) * 100);

      if (progress === 25 && depth25) {
        Mixpanel.track(MixpanelEvents.page_depth_viewed, {
          page_name: "twin-consultation",
          page_depth: progress,
        });
        setDepth25(false);
      } else if (progress === 50 && depth50) {
        Mixpanel.track(MixpanelEvents.page_depth_viewed, {
          page_name: "twin-consultation",
          page_depth: progress,
        });
        setDepth50(false);
      } else if (progress === 75 && depth75) {
        Mixpanel.track(MixpanelEvents.page_depth_viewed, {
          page_name: "twin-consultation",
          page_depth: progress,
        });
        setDepth75(false);
      } else if (progress === 100 && depth100) {
        Mixpanel.track(MixpanelEvents.page_depth_viewed, {
          page_name: "twin-consultation",
          page_depth: progress,
        });
        setDepth100(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [depth25, depth50, depth75, depth100]);

  // Find The Operating System Of The User
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    let operatingSystem = "";

    if (userAgent.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    } else if (userAgent.indexOf("Mac") !== -1) {
      operatingSystem = "Mac OS";
    } else if (userAgent.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    } else if (userAgent.indexOf("Android") !== -1) {
      operatingSystem = "Android OS";
    } else if (userAgent.indexOf("iOS") !== -1) {
      operatingSystem = "iOS";
    } else {
      operatingSystem = "Unknown";
    }

    setOperatingSystem(operatingSystem);
  }, []);

  // Find The Device Type Of The User
  useEffect(() => {
    // Function to update device type based on screen width
    const updateDeviceType = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceType("Mobile");
      } else if (width <= 1024) {
        setDeviceType("Tablet");
      } else {
        setDeviceType("Desktop");
      }
    };

    // Update device type when the component mounts
    updateDeviceType();

    // Add an event listener to update device type when the window is resized
    window.addEventListener("resize", updateDeviceType);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  // Function --> Form Data send to LSQ
  const formDataToLsq = async values => {
    setLoading(true);
    // leadSquared params
    let leadSquaredParams = {
      FirstName: values.name,
      Phone: values.phone,
      EmailAddress: values.email,
      mx_City: values.city,
      mx_Language: values.language,
      mx_Preferred_time_of_call: values.timeSlot,
      mx_Website_CTA: "us_landing_page",
      mx_Latest_Source: "New Website",
      mx_Device_Mobile_Desktop: deviceType,
      mx_msc_Operating_System: operatingSystem,
    };

    await sendToLSQ(leadSquaredParams);
    setLoading(false);
    // redirection to thank you page
    navigate("/twin-landing-ty");
  };

  const handleMixpanelCTA = (ctaText, ctaLocation) => {
    Mixpanel.track(MixpanelEvents.contact_button_clicked, {
      page_name: "twin-consultation",
      cta_text: ctaText,
      cta_location: ctaLocation,
    });
  };

  return (
    <div>
      <Seo
        title="Achieve Diabetes Remission, reclaim your health."
        description="Achieve Diabetes Remission. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
      />

      <Header handleMixpanelCTA={handleMixpanelCTA} />
      <Banner handleMixpanelCTA={handleMixpanelCTA} />
      <TwinContent />
      <PersonalizedSolution />
      <TwinReverse />
      <Testimonial />
      <GetInTouch handleMixpanelCTA={handleMixpanelCTA} />
      <WhatIsTwin />
      <SuccessfulSection />
      <FaqSection />
      <HealthAndHappinessSec />
      <div id="connectUsForm__LP">
        <ConnectUsForm formDataToLsq={formDataToLsq} loading={loading} />
      </div>
    </div>
  );
};

export default TwinConsultationPage;
