import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

// utils
import { getSessionId } from "../../../utils";

// component
import VideoModal from "../VideoModal/VideoModal";

// css
import * as styles from "./Template_1.module.css";

const WhatIsTwin = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [sessionId, setSessionId] = useState(getSessionId());

  // let videoUrl =
  // "https://player.vimeo.com/video/496207322?loop=false&autoplay=false&muted=false&gesture=media&playsinline=false&byline=false&portrait=false&title=false&speed=true&transparent=false&customControls=true";

  let videoUrl = "https://www.youtube.com/embed/wlnv1ooaxCg";

  const toggleVideo = () => {
    if (videoUrl !== "") setShowVideoModal(!showVideoModal);
  };

  const updateSessionId = () => {
    setSessionId(getSessionId());
  };

  return (
    <div className="container-fluid">
      <div className={styles.whatTwin__hdng}>
        <h3>
          What is <span>Twin?</span>
        </h3>
      </div>
      <div className={styles.whatTwin__imgSec}>
        <div
          className={styles.whatTwin__img}
          role="presentation"
          onClick={() => {
            toggleVideo();
          }}
        >
          <StaticImage
            src="../../../images/LandingPage/banner_4.png"
            alt=""
            className="img-fluid"
            placeholder="blurred"
            quality={90}
          />
        </div>

        {/* Sub-Heading */}
        <div className={`text-center ${styles.whatTwin__subHdng}`}>
          <p>
            Dr. Lisa Shah explains the medical science and{" "}
            <span>Whole Body Digital Twin™</span> technology for safely
            achieving diabetes remission of type 2 diabetes.
          </p>
        </div>
      </div>
      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={videoUrl}
        sessionId={sessionId}
        videoName={"What is Twin"}
        updateSessionId={updateSessionId}
        pageName="twin-consultation"
      />
    </div>
  );
};

export default WhatIsTwin;
